<template>
  <v-card class="mt-5">
    <v-toolbar
      flat
      dense
      dark
      color="secondary"
    >
      <v-spacer />
      <v-toolbar-title v-text="$t('multiproject.startNewWeek.title')" />
      <v-spacer />
    </v-toolbar>
    <v-card-text class="text-center">
      <v-btn
        :loading="loadingGetCapacity"
        :disabled="loadingGetCapacity"
        color="accent"
        @click="getCapacity"
        v-text="$t('multiproject.startNewWeek.button')"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import AVAILABLE_STATUS from '../availableStatus'
import AVAILABLE_ACTIONS from '../availableActions'

export default {
  name: 'StartNewWeekContainer',
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
  },
  inject: [
    'sendPlayerAction',
  ],
  data: () => ({
    loadingGetCapacity: false,
  }),
  computed: {
    currentPlayer () {
      return this.game.playerList.find(player => player.id === this.playerId)
    },
    currentStatus () {
      return this.currentPlayer.status
    },
    projectStarted () {
      return this.currentStatus === AVAILABLE_STATUS.readyToStartNewWeek
    },
  },
  methods: {
    async getCapacity () {
      this.loadingGetCapacity = true
      await this.sendPlayerAction(AVAILABLE_ACTIONS.startNewWeek)
      this.loadingGetCapacity = false
    },
  },
}
</script>
